import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
  ResponseType
} from "axios";
import { RootState } from "../redux/reducers";
import { store } from "../redux/store";
import { baseUrl } from "./EndPoints";
import { showLoader } from "../redux/actions/loader_actions";
import { showToaster } from "../redux/actions/toaster_actions";
import { ToastTypes } from "../Constants/enums";
import i18next from "i18next";
import DataError from "../errors/DataError";

export class APIService {
  axiosNoInterceptor: AxiosInstance;
  axiosOptions: AxiosRequestConfig = {
    timeout: 10000 * 10,
    withCredentials: true,
  };

  BaseDomain = {
    APP_ID: process.env.APP_ID,
    APP_VERSION: process.env.APP_VERSION,
  };
  ContentHeaders = {
    Json: "application/json",
    FormData: "multipart/form-data",
    Plain: "text/plain",
    Binary: "application/octet-stream",
    Excel: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  };

  apiCount = 0;
  constructor() {
    this.axiosNoInterceptor = axios.create();
    axios.interceptors.request.use((request) => this.requestHandler(request));
    axios.interceptors.response.use(
      (response) => {
        this.reduceAPICount();
        return response;
      },
      (error: AxiosError) => {
        this.reduceAPICount();
        if (error.response) {
          // if auth token expires, fetch auth token by passing refresh token
          if (error.response.status === 401) {
            store.dispatch(
              showToaster({
                text: i18next?.t("dashboard.session_expired_message"),
                type: ToastTypes.Warn,
                time: 5000,
              })
            );
          }
          if (error.response.status >= 400) {
            return new DataError(error.response.statusText, error.response);
          }
        }
        return this.responsePromise(error);
      }
    );

    this.axiosNoInterceptor.interceptors.response.use(
      (response) => {
        this.reduceAPICount();
        return response;
      },
      (error : AxiosError) => {
        this.reduceAPICount();
        if (error.response && error.response.status === 400) {
          return new Promise((_, reject) => {
            if (error.response) {
              reject(new DataError(error.response.statusText, error.response));
            } else {
              reject(new DataError(error.name, null));
            }
          });
        }
        return this.responsePromise(error);
      }
    );
  }

  reduceAPICount() {
    this.apiCount--;
    if (this.apiCount === 0) {
      store.dispatch(showLoader(false));
    }
  }

  delete(data: {
    domain?: string;
    endPoint: string;
    headerType?: string;
    id?: string;
    payLoad?: unknown;
    showLoader?: boolean;
  }) {
    if (!data.domain) {
      data.domain = baseUrl;
    }
    if (!data.headerType) {
      data.headerType = this.ContentHeaders.Json;
    }

    if (data.showLoader !== false) {
      data.showLoader = true;
    }

    if (data.showLoader) {
      store.dispatch(showLoader(true));
    }

    return axios.delete(data.endPoint, {
      baseURL: data.domain,
      headers: this.getHeadersByType(data.headerType),
      data: data.payLoad,
    });
  }

  get(data: {
    domain: string;
    endPoint: string;
    headerType?: string;
    id?: string;
    noHeadersRequired?: boolean;
    payLoad?: unknown;
    showLoader?: boolean;
    responseType?: ResponseType;
  }) {
    if (!data.domain) {
      data.domain = baseUrl;
    }
    if (!data.headerType) {
      data.headerType = this.ContentHeaders.Json;
    }
    if (data.showLoader || data.showLoader === undefined) {
      data.showLoader = true;
    }

    return axios.get(data.endPoint, {
      baseURL: data.domain,
      timeout: this.axiosOptions.timeout,
      params: data.payLoad,
      responseType: data.responseType,
      headers: data.noHeadersRequired
        ? null
        : this.getHeadersByType(data.headerType),
    });
  }

  getHeadersByType(headerType: string | undefined) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = {};
    switch (headerType) {
      case this.ContentHeaders.Json: {
        data["Content-Type"] = "application/json";
        break;
      }
      case this.ContentHeaders.Plain: {
        data["Content-Type"] = "text/plain";
        break;
      }
      case this.ContentHeaders.FormData: {
        data["Content-Type"] = "multipart/form-data";
        break;
      }
      case this.ContentHeaders.Binary: {
        data["Content-Type"] = "application/octet-stream";
        break;
      }
      case this.ContentHeaders.Excel: {
        data["Content-Type"] =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      }
      default:
        data["Content-Type"] = "application/json";
        break;
    }
    const appState: RootState = store.getState();

    const authorization = appState.auth ? appState.auth.authToken : "";
    const token = appState.auth ? appState.auth.userToken : "";
    if (authorization) {
      data["Authorization"] = `Bearer ${authorization}`;
    }
    else if (token) {
      data["Authorization"] = `Bearer ${token}`;
    }
    return data;
  }

  post(data: {
    domain?: string;
    endPoint: string;
    headerType?: string;
    payLoad?: unknown;
    showLoader?: boolean;
    useNonInterceptor?: boolean;
  }) {
    if (!data.domain) {
      data.domain = baseUrl;
    }
    if (!data.headerType) {
      data.headerType = this.ContentHeaders.Json;
    }

    if (data.showLoader !== false) {
      data.showLoader = true;
    }

    if (!data.useNonInterceptor) {
      data.useNonInterceptor = false;
    }

    if (data.useNonInterceptor) {
      const payLoadData =
        data.payLoad instanceof FormData
          ? data.payLoad
          : JSON.stringify(data.payLoad);
      return this.axiosNoInterceptor.post(data.endPoint, payLoadData, {
        timeout: this.axiosOptions.timeout,
        transformRequest: this.axiosOptions.transformRequest,
        baseURL: data.domain,
        headers: this.getHeadersByType(data.headerType),
      });
    } else {
      const payLoadData =
        data.payLoad instanceof FormData
          ? data.payLoad
          : JSON.stringify(data.payLoad);
      return axios.post(data.endPoint, payLoadData, {
        timeout: this.axiosOptions.timeout,
        transformRequest: this.axiosOptions.transformRequest,
        baseURL: data.domain,
        headers: this.getHeadersByType(data.headerType),
      });
    }
  }
  put(data: {
    domain?: string;
    endPoint: string;
    headerType?: string;
    id?: string;
    payLoad?: unknown;
    showLoader?: boolean;
  }) {
    if (!data.domain) {
      data.domain = baseUrl;
    }
    if (!data.headerType) {
      data.headerType = this.ContentHeaders.Json;
    }
    if (data.showLoader !== false) {
      data.showLoader = true;
    }

    const payLoadData =
      data.payLoad instanceof FormData
        ? data.payLoad
        : JSON.stringify(data.payLoad);
    return axios.put(data.endPoint, payLoadData, {
      timeout: this.axiosOptions.timeout,
      transformRequest: this.axiosOptions.transformRequest,
      baseURL: data.domain,
      headers: this.getHeadersByType(data.headerType),
    });
  }

  requestHandler(requestObject: InternalAxiosRequestConfig<unknown>) {
    if (this.apiCount === 0) {
      store.dispatch(showLoader(true));
    }
    this.apiCount++;
    return requestObject;
  }

  responsePromise(res: AxiosError) {
    this.apiCount--;
    if (this.apiCount === 0) {
      store.dispatch(showLoader(false));
    }
    return new Promise((resolve, reject) => {
      if (res.response) {
        resolve(res);
      } else {
        reject(res);
      }
    });
  }

  uploadFile = (data: {
    domain?: string;
    endPoint: string;
    headerType?: string;
    payLoad?: unknown;
    showLoader?: boolean;
    useNonInterceptor?: boolean;
  }) => {
    if (!data.domain) {
      data.domain = baseUrl;
    }
    if (!data.headerType) {
      data.headerType = this.ContentHeaders.Json;
    }

    if (data.showLoader !== false) {
      data.showLoader = true;
    }

    if (!data.useNonInterceptor) {
      data.useNonInterceptor = false;
    }
    if (data.useNonInterceptor) {
      const payLoadData =
        data.payLoad instanceof FormData
          ? data.payLoad
          : JSON.stringify(data.payLoad);
      return this.axiosNoInterceptor.post(data.endPoint, payLoadData, {
        timeout: this.axiosOptions.timeout,
        transformRequest: this.axiosOptions.transformRequest,
        baseURL: data.domain,
        headers: this.getHeadersByType(data.headerType),
      });
    } else {
      const payLoadData =
        data.payLoad instanceof FormData
          ? data.payLoad
          : JSON.stringify(data.payLoad);
      return axios.post(data.endPoint, payLoadData, {
        timeout: this.axiosOptions.timeout,
        transformRequest: this.axiosOptions.transformRequest,
        baseURL: data.domain,
        headers: this.getHeadersByType(data.headerType),
      });
    }
  };
}

export const apiService = new APIService();
