import moment from "moment";
import { setLanguageOfApp } from "./Wrapper";
import axios, { AxiosError } from "axios";
import { I18nType, type TranslationDBType } from "./type";
import DataError from "../errors/DataError";

async function loadLangFromPhrase(): Promise<TranslationDBType> {
  const host = "https://api.phrase.com/v2";
  const projectId = process.env.REACT_APP_PHRASE_PROJECT_ID;
  const accessToken = process.env.REACT_APP_PHRASE_ACCESS_TOKEN;
  const queryParams = `access_token=${accessToken}&file_format=i18next`; // &branch=${branch}
  const baseURL = `${host}/projects/${projectId}/locales`;
  const locales = await axios.get(`${baseURL}?${queryParams}`);
  const promises = locales.data.map(async (locale: { code: string; id: string }) => {
    try {
      const url = `${baseURL}/${locale.id}/download?${queryParams}`;
      const res = await axios.get<Record<string, unknown>>(url, { headers: { "cache-control": "no-cache" } });
      return {
        [locale.code]: {
          translation: res.data,
        },
      }
    } catch (e) {
      return e as Error | AxiosError
    }
  });
  const localesArr: (AxiosError | Error | Record<string, { translation: unknown }>)[] = await Promise.all(promises);
  if (localesArr.some(i => i instanceof Error)) throw new DataError("Failed to load translations", localesArr);
  return (localesArr as Record<string, unknown>[]).reduce((a: Record<string, unknown>, b: Record<string, unknown>) => ({ ...b, ...a }), {}) as TranslationDBType;
}

export const getAllTranslation = async (i18n: I18nType): Promise<boolean> => {
  try {
    const translationData = await loadLangFromPhrase();
    setLanguageOfApp(translationData, i18n);
    const currentTimeStamp = new Date().toISOString();
    // Store the update timestamp
    localStorage.setItem("PHRASE_TRANSLATION_UPDATE", currentTimeStamp);
    // Store the translation data
    localStorage.setItem(
      "LANGUAGE_TRANSLATION",
      JSON.stringify(translationData)
    );

    return true; // Resolve with a boolean indicating success
  } catch (error) {
    // Reject with the caught error
    throw new DataError("Failed to load translations", error);
  }
};

/**
 * Update phrase translation after every 3 days
 */
export const isPhraseTranslationUpdateRequired = async (i18n: I18nType) => {
  try {
    const isPhraseNeedUpdate = localStorage.getItem(
      "PHRASE_TRANSLATION_UPDATE"
    );
    if (isPhraseNeedUpdate === undefined || isPhraseNeedUpdate === null) {
      localStorage.setItem(
        "PHRASE_TRANSLATION_UPDATE",
        `${new Date().toISOString()}`
      );
    } else {
      const savedTime = moment(isPhraseNeedUpdate).locale("en");
      const currentTime = moment(new Date().toISOString()).locale("en");
      const numberOfDays: number = currentTime.diff(savedTime, "days");
      if (numberOfDays >= 3) {
        await getAllTranslation(i18n);
      }
    }
  } catch (error) {
    console.error(error);
  }
};
